import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import MoodButton from '../components/MoodButton';

const suggestions = {
  mood: ['Romantic', 'Dance', 'Relaxation', 'Focus', 'Happy', 'Sad', 'Energetic', 'Calm'],
  artist: ['The Weeknd', 'Kanye West', 'AC/DC', 'Beyoncé', 'Drake', 'Billie Eilish', 'Ed Sheeran', 'Adele', ],
  prompt: ['Gym motivational high energy dance house', 'Relaxing acoustic chillout', 'Focus instrumental upbeat', 'Party hits for a great night', 'Calm evening with jazz and blues', 'Uplifting indie pop for a good mood', 'Deep house for concentration', 'Chill beats for studying']
};

const MoodSelection: React.FC = () => {
  const [selectedMood, setSelectedMood] = useState<string | null>(null);
  const [selectedArtist, setSelectedArtist] = useState<string | null>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);

  const [customMood, setCustomMood] = useState<string>('');
  const [customArtist, setCustomArtist] = useState<string>('');
  const [customPrompt, setCustomPrompt] = useState<string>('');

  const [filteredMoodSuggestions, setFilteredMoodSuggestions] = useState<string[]>(suggestions.mood);
  const [filteredArtistSuggestions, setFilteredArtistSuggestions] = useState<string[]>(suggestions.artist);
  const [filteredPromptSuggestions, setFilteredPromptSuggestions] = useState<string[]>(suggestions.prompt);

  const navigate = useNavigate();

  const handleSelectMood = (mood: string) => {
    setSelectedMood(mood);
    setCustomMood(mood);
    setFilteredMoodSuggestions([]);
  };

  const handleSelectArtist = (artist: string) => {
    setSelectedArtist(artist);
    setCustomArtist(artist);
    setFilteredArtistSuggestions([]);
  };

  const handleSelectPrompt = (prompt: string) => {
    setSelectedPrompt(prompt);
    setCustomPrompt(prompt);
    setFilteredPromptSuggestions([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'mood' | 'artist' | 'prompt') => {
    const value = e.target.value;
    if (type === 'mood') {
      setCustomMood(value);
      setSelectedMood(null);
      setFilteredMoodSuggestions(suggestions.mood.filter(s => s.toLowerCase().includes(value.toLowerCase())));
    } else if (type === 'artist') {
      setCustomArtist(value);
      setSelectedArtist(null);
      setFilteredArtistSuggestions(suggestions.artist.filter(s => s.toLowerCase().includes(value.toLowerCase())));
    } else if (type === 'prompt') {
      setCustomPrompt(value);
      setSelectedPrompt(null);
      setFilteredPromptSuggestions(suggestions.prompt.filter(s => s.toLowerCase().includes(value.toLowerCase())));
    }
  };

  const handleGeneratePlaylist = (type: 'mood' | 'artist' | 'prompt') => {
    let mood = selectedMood || customMood;
    let artist = selectedArtist || customArtist;
    let prompt = selectedPrompt || customPrompt;
    localStorage.setItem('category', type);
    if (type === 'mood') {
      localStorage.setItem('mood', mood || '');
    } else if (type === 'artist') {
      localStorage.setItem('artist', artist || '');
    } else if (type === 'prompt') {
      localStorage.setItem('prompt', prompt || '');
    }

    navigate('/playlist-display');
  };

  const isMoodButtonDisabled = !(
    selectedMood || customMood
  );

  const isArtistButtonDisabled = !(
    selectedArtist || customArtist
  );

  const isPromptButtonDisabled = !(
    selectedPrompt || customPrompt
  );

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center bg-[#191414] text-white p-6 min-h-screen overflow-y-auto">
        <h1 className="text-4xl font-bold mb-8">Make your playlist</h1>

        <div className="w-full max-w-4xl mb-12 p-6 bg-gray-800 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Mood</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
            {suggestions.mood.map((mood) => (
              <MoodButton key={mood} mood={mood} onSelectMood={handleSelectMood} />
            ))}
          </div>
          <input
            type="text"
            value={customMood}
            onChange={(e) => handleInputChange(e, 'mood')}
            placeholder="Or type your mood"
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-800 text-white placeholder-gray-400"
          />
          {filteredMoodSuggestions.length > 0 && customMood && (
            <ul className="bg-gray-700 rounded-lg mt-1">
              {filteredMoodSuggestions.map((s, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => handleSelectMood(s)}
                >
                  {s}
                </li>
              ))}
            </ul>
          )}
          {selectedMood || customMood ? (
            <p className="mt-2 text-lg">
              <strong>Selected Mood:</strong> {selectedMood || customMood}
            </p>
          ) : null}
          <button
            className="w-full mt-4 p-3 bg-[#1DB954] text-white rounded-lg hover:bg-[#1DB954]-700 transition duration-300"
            onClick={() => handleGeneratePlaylist('mood')}
            disabled={isMoodButtonDisabled}
          >
            Generate Playlist
          </button>
        </div>

        <div className="w-full max-w-4xl mb-12 p-6 bg-gray-800 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Favorite Artists</h2>
          <div className="flex flex-wrap gap-4 mb-4">
            {suggestions.artist.map((artist) => (
              <MoodButton key={artist} mood={artist} onSelectMood={handleSelectArtist} />
            ))}
          </div>
          <input
            type="text"
            value={customArtist}
            onChange={(e) => handleInputChange(e, 'artist')}
            placeholder="Or type your favorite artist"
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-800 text-white placeholder-gray-400"
          />
          {filteredArtistSuggestions.length > 0 && customArtist && (
            <ul className="bg-gray-700 rounded-lg mt-1">
              {filteredArtistSuggestions.map((s, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => handleSelectArtist(s)}
                >
                  {s}
                </li>
              ))}
            </ul>
          )}
          {selectedArtist || customArtist ? (
            <p className="mt-2 text-lg">
              <strong>Selected Artist:</strong> {selectedArtist || customArtist}
            </p>
          ) : null}
          <button
            className="w-full mt-4 p-3 bg-[#1DB954] text-white rounded-lg hover:bg-[#1DB954]-700 transition duration-300"
            onClick={() => handleGeneratePlaylist('artist')}
            disabled={isArtistButtonDisabled}
          >
            Generate Playlist
          </button>
        </div>

        <div className="w-full max-w-4xl mb-12 p-6 bg-gray-800 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Suggested Prompts</h2>
          <div className="flex flex-wrap gap-4 mb-4">
            {suggestions.prompt.map((prompt) => (
              <MoodButton key={prompt} mood={prompt} onSelectMood={handleSelectPrompt} />
            ))}
          </div>
          <input
            type="text"
            value={customPrompt}
            onChange={(e) => handleInputChange(e, 'prompt')}
            placeholder="Or type your own prompt"
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-800 text-white placeholder-gray-400"
          />
          {filteredPromptSuggestions.length > 0 && customPrompt && (
            <ul className="bg-gray-700 rounded-lg mt-1">
              {filteredPromptSuggestions.map((s, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-600 cursor-pointer"
                  onClick={() => handleSelectPrompt(s)}
                >
                  {s}
                </li>
              ))}
            </ul>
          )}
          {selectedPrompt || customPrompt ? (
            <p className="mt-2 text-lg">
              <strong>Selected Prompt:</strong> {selectedPrompt || customPrompt}
            </p>
          ) : null}
          <button
            className="w-full mt-4 p-3 bg-[#1DB954] text-white rounded-lg hover:bg-[#1DB954]-700 transition duration-300"
            onClick={() => handleGeneratePlaylist('prompt')}
            disabled={isPromptButtonDisabled}
          >
            Generate Playlist
          </button>
        </div>
      </div>
    </>
  );
};

export default MoodSelection;
