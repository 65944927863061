import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const Navbar: React.FC = () => {
  const { logout } = useAuth();

  return (
    <nav className="flex items-center justify-between bg-[#191414] text-white p-4">
        <h1 className="text-4xl font-extrabold mb-2 text-center tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
          Playlists Maker
        </h1>      <div className="flex items-center">
        <button 
          onClick={logout} 
          className="bg-[#F44336] text-white px-4 py-2 rounded"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
