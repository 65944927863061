import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSpotifyTokens } from '../services/api';

const Callback: React.FC = () => {
  const navigate = useNavigate();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    const getTokens = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        
        if (code) {
          const tokens = await getSpotifyTokens(code);
          
          if (tokens.accessToken) {
            localStorage.setItem('spotifyAccessToken', tokens.accessToken);
            localStorage.setItem('spotifyRefreshToken', tokens.refreshToken);

            navigate('/'); 
          }
        } else {
          navigate('/'); 
          throw new Error('Authorization code not found');
        }
      } catch (error) {
        navigate('/'); 
        console.error('Failed to authenticate', error);
      }
    };

    if (!hasFetchedData.current) {
      getTokens();
      hasFetchedData.current = true;
    }
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#191414] text-white">
      <div className="flex flex-col items-center space-y-6">
        <h1 className="text-8xl font-extrabold mb-2 text-center tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500" style={{ lineHeight: '1.2' }}>
          Playlists Maker
        </h1>
        <button 
          disabled={true}
          className={`bg-[#1ed760] text-white font-semibold px-8 py-4 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out opacity-50 cursor-not-allowed'}`}
        >
          Authenticating...
        </button>
      </div>
    </div>
  );
};

export default Callback;
