import React from 'react';

interface MoodButtonProps {
  mood: string;
  onSelectMood: (mood: string) => void;
}

const MoodButton: React.FC<MoodButtonProps> = ({ mood, onSelectMood }) => {
  const handleClick = () => {
    onSelectMood(mood);
  };

  return (
    <button
      onClick={handleClick}
      className="bg-[#2A52BE] text-[#E0E0E0] font-bold py-2 px-4 rounded-lg hover:bg-[#2A52BE]-700 transition-all duration-200 ease-in-out"
    >
      {mood}
    </button>
  );
};

export default MoodButton;
