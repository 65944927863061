import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import MoodSelection from './pages/MoodSelection';
import PlaylistDisplay from './pages/PlaylistDisplay';
import Callback from './pages/CallbackSpotify';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/callback" element={<Callback />} />

            <Route
              path="/mood-selection"
              element={<ProtectedRoute element={<MoodSelection />} />}
            />
            <Route
              path="/playlist-display"
              element={<ProtectedRoute element={<PlaylistDisplay />} />}
            />
          </Routes>
        </div>
      </Router>
      {
        process.env.NODE_ENV === 'production' ?
          <noscript><div><img src="https://mc.yandex.ru/watch/98506062" style={{position:"absolute", left:-9999}} alt="" /></div></noscript>
          :
          null
      }
    </AuthProvider>
  );
};

export default App;
