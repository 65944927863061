import React, { useState, useEffect, useRef } from 'react';
import { fetchPlaylist, savePlaylist } from '../services/api';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';

const PlaylistDisplay: React.FC = () => {
  const [playlist, setPlaylist] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [playlistName, setPlaylistName] = useState<string>(''); 
  const hasFetchedData = useRef(false);
  const navigate = useNavigate();

  const category = localStorage.getItem('category'); 
  const selection = localStorage.getItem(category || 'mood') || ''; 

  useEffect(() => {
    const getPlaylist = async () => {
      try {
        const data = await fetchPlaylist(selection, category || 'mood');
        setPlaylist(data.tracks);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (!hasFetchedData.current) {
      getPlaylist();
      hasFetchedData.current = true;
    }
  }, [selection, category]);

  const handleSavePlaylist = async () => {
    try {
      setIsSaving(true);
      const trackUris = playlist.map(track => track.uri);

      await savePlaylist(trackUris, playlistName);
      alert('Playlist saved successfully!');
    } catch (error) {
      alert('Playlist saving failed');
    } finally {
      setIsSaving(false);
    }
  };

  const formatDuration = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleTrackClick = (track: any) => {
    window.open(track.external_url, '_blank'); 
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center p-4 bg-[#191414] text-white min-h-screen">
        <h1 className="text-3xl font-bold mb-12">Your Playlist ({selection})</h1>

        {isLoading ? (
          <p className="text-xl">Generating your playlist...</p>
        ) : (
          <>
            <div className="mb-6 w-full max-w-6xl flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
              <input
                type="text"
                placeholder="Enter playlist name"
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
                className="w-full sm:w-1/3 p-2 bg-[#1c1c1c] border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                className="w-full sm:w-auto px-4 py-2 bg-[#1DB954] text-white rounded-lg hover:bg-[#1DB954] transition duration-300 mb-2 sm:mb-0"
                onClick={handleSavePlaylist}
                disabled={isSaving || !playlistName}
              >
                {isSaving ? 'Saving Playlist...' : 'Save Playlist'}
              </button>
              <button
                className="w-full sm:w-auto px-4 py-2 bg-[#9C27B0] text-white rounded-lg hover:bg-[#9C27B0]-600 transition duration-300 mb-2 sm:mb-0"
                onClick={() => navigate('/mood-selection')}
              >
                Create another playlist
              </button>
            </div>

            <div className="w-full max-w-6xl overflow-x-auto">
              <div className="hidden sm:block bg-[#1c1c1c] rounded-lg mb-6">
                <div className="grid grid-cols-12 text-gray-400 font-bold mb-2 text-center p-4">
                  <div className="col-span-1 mr-4">#</div>
                  <div className="col-span-5">Track</div>
                  <div className="col-span-4">Album</div>
                  <div className="col-span-2">Duration</div>
                </div>

                {playlist.length > 0 ? (
                  <div className="min-w-full">
                    {playlist.map((track, index) => (
                      <div
                        key={track.id}
                        className="grid grid-cols-12 items-center p-4 border-b border-gray-700 last:border-b-0 hover:bg-[#2c2c2c] transition duration-200 cursor-pointer text-center"
                        onClick={() => handleTrackClick(track)}
                      >
                        <div className="col-span-1 mr-4">{index + 1}</div>
                        <div className="col-span-5 flex items-center">
                          <img src={track.albumCover} alt={`${track.name} cover`} className="w-16 h-16 rounded inline-block mr-4" />
                          <div className="text-left">
                            <div className="text-left flex items-center">
                              <p className="text-lg font-semibold">{track.name}</p>
                              {/* <img src={icon} alt="icon" className="w-4 h-4" /> */}
                            </div>
                            <p className="text-gray-400 text-sm">{track.artist}</p>
                          </div>
                        </div>
                        <div className="col-span-4">{track.albumName}</div>
                        <div className="col-span-2">{formatDuration(track.duration)}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-xl text-center p-4">No songs found</p>
                )}
              </div>

              <div className="block sm:hidden bg-[#1c1c1c] rounded-lg mb-6">
                <div className="grid grid-cols-12 text-gray-400 font-bold mb-2 text-center p-4">
                    <div className="col-span-1 mr-4">#</div>
                    <div className="col-span-9">Track</div>
                    <div className="col-span-2">Duration</div>
                  </div>

                  {playlist.length > 0 ? (
                    <div className="min-w-full">
                      {playlist.map((track, index) => (
                        <div
                          key={track.id}
                          className="grid grid-cols-12 items-center p-4 border-b border-gray-700 last:border-b-0 hover:bg-[#2c2c2c] transition duration-200 cursor-pointer text-center"
                          onClick={() => handleTrackClick(track)}
                        >
                          <div className="col-span-1 mr-4">{index + 1}</div>
                          <div className="col-span-9 flex items-center">
                            <img src={track.albumCover} alt={`${track.name} cover`} className="w-12 h-12 rounded inline-block mr-4" />
                            <div className="text-left">
                              <p className="text-lg font-semibold">{track.name}</p>
                              <p className="text-gray-400 text-sm">{track.artist}</p>
                            </div>
                          </div>
                          <div className="col-span-2">{formatDuration(track.duration)}</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-xl text-center p-4">No songs found</p>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PlaylistDisplay;
