import React, { useEffect } from 'react';
import { loginWithSpotify } from '../services/spotify';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('spotifyAccessToken');
    
    if (accessToken) {
      navigate('/mood-selection');
    }
  }, [navigate]);

  const handleLogin = () => {
    loginWithSpotify();
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#191414] text-white">
      <div className="flex flex-col items-center space-y-6">
        <h1 className="text-8xl font-extrabold mb-2 text-center tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500" style={{ lineHeight: '1.2' }}>
          Playlists Maker
        </h1>
        <button 
          onClick={handleLogin} 
          className="bg-[#1ed760] hover:bg-green-500 text-white font-semibold px-8 py-4 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
        >
          Login with Spotify
        </button>
      </div>
    </div>
  );
};

export default Login;
