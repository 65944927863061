import axios from 'axios';

const logoutUser = () => {
  localStorage.removeItem('spotifyAccessToken');
  localStorage.removeItem('spotifyRefreshToken');
  window.location.href = '/';
};

const API_URL = process.env.REACT_APP_API_URI || '';

const refreshSpotifyToken = async (refreshToken: string): Promise<string> => {
  try {
    const response = await axios.post(`${API_URL}/api/token/refresh`, {
      refreshToken,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { accessToken } = response.data;

    localStorage.setItem('spotifyAccessToken', accessToken);
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    logoutUser();
    throw error;
  }
};

const handleError = async (error: unknown, retryCallback: () => Promise<any>) => {
  if (axios.isAxiosError(error)) {
    const status = error.response?.status;

    if (status === 401) {
      const refreshToken = localStorage.getItem('spotifyRefreshToken');
      if (refreshToken) {
        try {
          await refreshSpotifyToken(refreshToken);
          return await retryCallback();
        } catch (refreshError) {
          alert('Session expired. Please log in again.');
          logoutUser();
        }
      } else {
        alert('Session expired. Please log in again.');
        logoutUser();
      }
    } else {
      alert(`An error occurred: ${error.message}`);
    }
  } else {
    alert('An unknown error occurred.');
  }
  throw error;
};

export const getSpotifyTokens = async (code: string): Promise<{ accessToken: string; refreshToken: string; expiresIn: number }> => {
  try {
    const response = await axios.post(`${API_URL}/api/token`, {
      code,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { accessToken, refreshToken, expiresIn } = response.data;

    return {
      accessToken,
      refreshToken,
      expiresIn,
    };
  } catch (error: unknown) {
    handleError(error, () => getSpotifyTokens(code));
    throw error;
  }
};

export const fetchPlaylist = async (mood: string, category: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('spotifyAccessToken');

    const response = await axios.post(`${API_URL}/api/playlist/generate`, {
      mood,
      accessToken,
      category,
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error: unknown) {
    return handleError(error, () => fetchPlaylist(mood, category));
  }
};

// Tipagem para a função de salvar a playlist
export const savePlaylist = async (trackUris: string[], playlistName: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('spotifyAccessToken');

    const response = await axios.post(`${API_URL}/api/playlist/save`, {
      trackUris,
      accessToken,
      playlistName,
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error: unknown) {
    return handleError(error, () => savePlaylist(trackUris, playlistName));
  }
};
